import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import FullWidthImage from '../components/FullWidthImage'
import ImageWithParagraph from '../components/ImageWithParagraph'
import Image from '../components/Image'
import NextSections from '../components/NextSections'

//import images
import fullImage from '../images/petite-week-merch/petite-full.jpg'
import backgroundImage from '../images/petite-week-merch/petite-bk.jpg'
import supporting1 from '../images/petite-week-merch/petite-supporting-1.jpg'
import supporting2 from '../images/petite-week-merch/petite-supporting-2.jpg'

export default () => (
    <Project
        title={'petite week merchandise'}
            date={'2018'}
            position={'Graphic Designer'}
            employer={'Boscovs Department Store'}
            brief={[
                `Petite Week is an annual sale during which discounts are offered throughout the Women’s, Men’s and Children’s Departments at Boscov’s. It is an important event for the store as it helps to make way for new merchandise. Though the sale runs through all clothing departments, the focus needed to remain on their core customer: women.`,
                `A color-blocked background was requested to be incorporated into each display. The pattern helped to inspire the whimsical and feminine tone of each piece, resulting in two hanging posters and one full-sized trend wrap that was placed at the major intersections of the Women’s Department. The signs were successful in motivating shopping to take part in the sale and interacted seamlessly with the surrounding environment.`
            ]}
            skills={[
                'Illustration & Layout Design',
                'Photo Manipulation',
                'Large Format Printing'
            ]}
    >
        <FullWidthImage src={fullImage} alt={'Poster mockups on yellow background'} />
        <ImageWithParagraph
            background={backgroundImage}
            title={'REQUIRED IMAGE'}
            text={'This image was adapted and incorporated into each piece. Using the background created cohesion and therefore, a clearly branded identity for the sale. It provided a set color palette and feminine tone to work with.'}
        />
        <Padding />
        <Image
            src={supporting1}
            alt={'Posters in department store'}
            size={800}
        />
        <Padding />
        <Image
            src={supporting2}
            alt={'Posters in department store'}
            size={800}
        />
        <Padding />
        <NextSections
            previous={'mens-comfort-decoded-merchandise'}
            next={'visual-narratives'}
        />
    </Project>
)